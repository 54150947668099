/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ConfirmationDialog from "src/component/Confirmation";
import Cookies from "js-cookie";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Slide,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import NavItem from "./NavItem";

const sections = [
  // {
  //   items: [
  //     {
  //       title: "Home",
  //       icon: "/images/home.svg",
  //       active: "/images/home.svg",
  //       href: "/",
  //     },
  //   ],
  // },
  {
    items: [
      {
        title: "Dashboard",
        icon: "/images/dashActive.svg",
        active: "/images/dashActive.svg",
        href: "/dash",
      },
    ],
  },
  {
    items: [
      {
        title: "User Management",
        icon: "/images/user.svg",
        active: "/images/user.svg",
        href: "/userManagement",
      },
    ],
  },
  {
    items: [
      {
        title: "KYC Management",
        icon: "/images/trans.svg",
        active: "/images/trans.svg",
        href: "/Kyc-Management",
      },
    ],
  },

  {
    items: [
      {
        title: "Bonus Management",
        icon: "/images/bonus.svg",
        active: "/images/bonus.svg",
        href: "/bonus-index",
      },
    ],
  },

  {
    items: [
      {
        title: "Award Management",
        icon: "/images/award.svg",
        active: "/images/award.svg",
        href: "/award-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Award Plan Management",
        icon: "/images/award.svg",
        active: "/images/award.svg",
        href: "/award-plan-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Withdraw Management",
        icon: "/images/Withdraw management.svg",
        active: "/images/Withdraw management.svg",
        href: "/withdraw-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Coupon Management",
        icon: "/images/dashActive.svg",
        active: "/images/dashActive.svg",
        href: "/coupon-management",
      },
    ],
  },
  // {
  //   items: [
  //     {
  //       title: "Insurance Management",
  //       icon: "/images/Insurance mangement.svg",
  //       active: "/images/Insurance mangement.svg",
  //       href: "/insurance-management",
  //     },
  //   ],
  // },
  {
    items: [
      {
        title: "Royalty Management",
        icon: "/images/royalty management.svg",
        active: "/images/royalty management.svg",
        href: "/royalty-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Loyalty Management",
        icon: "/images/royalty management.svg",
        active: "/images/royalty management.svg",
        href: "/loyalty-management",
      },
    ],
  },

  {
    items: [
      {
        title: "TDS Management",
        icon: "/images/royalty management.svg",
        active: "/images/royalty management.svg",
        href: "/tds-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Nominee Management",
        icon: "/images/Nominee.svg",
        active: "/images/Nominee.svg",
        href: "/nominee-management",
      },
    ],
  },

  {
    items: [
      {
        title: "Video Management",
        icon: "/images/banner.svg",
        active: "/images/banner.svg",
        href: "/video-management",
        items: [
          {
            title: "Video Category",
            icon: "/images/banner.svg",
            href: "/video-categories",
          },
          {
            title: "Manage Videos",
            icon: "/images/banner.svg",
            href: "/manage-videos",
          },
        ],
      },
    ],
  },
  // {
  //   items: [
  //     {
  //       title: "Course Management",
  //       icon: "/images/faqmain.svg",
  //       active: "/images/faqmain.svg",
  //       href: "/course-management",
  //     },
  //   ],
  // },
  {
    items: [
      {
        title: "Banner Management",
        icon: "/images/banner.svg",
        active: "/images/banner.svg",
        href: "/banner-management",
      },
    ],
  },

  {
    items: [
      {
        title: "Discount Management",
        icon: "/images/Discount management.svg",
        active: "/images/Discount management.svg",
        href: "/discount-management",
      },
    ],
  },

  {
    items: [
      {
        title: "Static Content Management",
        icon: "/images/static.svg",
        active: "/images/static.svg",
        href: "/static-content",
      },
    ],
  },

  {
    items: [
      {
        title: "FAQ Management",
        icon: "/images/faqmain.svg",
        active: "/images/faqmain.svg",
        href: "/faq-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Contact Support Management",
        icon: "/images/settlement.svg",
        active: "/images/settlement.svg",
        href: "/contact-support",
      },
    ],
  },
  {
    items: [
      {
        title: "Live Session Management",
        icon: "/images/bonus.svg",
        active: "/images/bonus.svg",
        href: "/category-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Feedback Management",
        icon: "/images/bonus.svg",
        active: "/images/bonus.svg",
        href: "/feedback",
      },
    ],
  },
  {
    items: [
      {
        title: "Reward Management",
        icon: "/images/royalty management.svg",
        active: "/images/royalty management.svg",
        href: "/reward-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Product Category Management",
        icon: "/images/settlement.svg",
        active: "/images/settlement.svg",
        href: "/product-category-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Product Catelogue Management",
        icon: "/images/settlement.svg",
        active: "/images/settlement.svg",
        href: "/product-catelogue-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Product Deal Management",
        icon: "/images/settlement.svg",
        active: "/images/settlement.svg",
        href: "/product-deal-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Order Management",
        icon: "/images/settlement.svg",
        active: "/images/settlement.svg",
        href: "/order-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Review Management",
        icon: "/images/settlement.svg",
        active: "/images/settlement.svg",
        href: "/review-management",
      },
    ],
  },

  // {
  //   items: [
  //     {
  //       title: "Feedback",
  //       icon: "/images/feedback.svg",
  //        active: "/images/feedback.svg",
  //       href: "/feedback",
  //     },
  //   ],
  // },
  // {
  //   items: [
  //     {
  //       title: "Logout",
  //       icon: "/images/log-out.svg",
  //       active: "/images/log-out.svg",
  //       href: "/",
  //     },
  //   ],
  // },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: true,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        active={item.active}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        active={item.active}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    //background: "rgb(11, 52, 131)",
    backdropFilter: "blur(44px)",
  },
  desktopDrawer: {
    width: 280,
    top: 0,
    height: "100%",
    //background: "#db953cbf",
    backgroundSize: "cover",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  logoutButton: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    color: "#fff",

    "&:hover": {
      color: "#fff",
      background: "rgb(90 134 255) !important",
      borderRadius: "none !important",
    },
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: "2px",
  },
  // buttonLeaf: {
  //   color: "#F7931E !important",
  //   padding: "11px 8px",
  //   justifyContent: "flex-start",
  //   textTransform: "none",
  //   letterSpacing: 0,
  //   borderLeft: "solid 8px transparent",
  //   borderRadius: 0,
  //   fontSize: "13px",
  //   borderLeft: "3px solid transparent",
  //   "&:hover": {
  //     background: "rgb(90 134 255)",
  //     color: "#FFFFFF",
  //   },

  //   "&.depth-0": {
  //     "& $title": {
  //       fontWeight: theme.typography.fontWeightMedium,
  //       color: "#F7931E !important"
  //     },
  //   },
  // },
  active: {
    borderLeft: "3px solid #fff",

    fontWeight: theme.typography.fontWeightRegular,
    background: "#263944",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "00e0b0",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [colorbg, setColor] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = useState(false);
  const confirmationHandler = () => {
    console.log("fffffff");
    Cookies.remove("auth");
    history.push("/login");
  };

  const [open1, setOpen1] = React.useState(false);

  const handleClick = (event, index) => {
    setOpen1(!open1);
    setSelectedIndex(index);
  };
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className="example"
    >
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
          style={{ color: "#fff" }}
        />
      )}
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          mt={2}
          style={{
            display: "flex",
            paddingLeft: "20px",
            // justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Box>
            <Link to="/dash">
              <img src="images/logo-png-high.svg" />
            </Link>
          </Box>
          &nbsp;&nbsp;&nbsp;
        </Box>
        &nbsp;
        <Box pt={1}>
          {sections.map((section, i) => (
            <List
              style={{ color: "black" }}
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location?.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          // className="drawerimg"
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
