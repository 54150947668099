export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = endDate * 1000 - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      
      if (timeLeft.minutes < 10) {
        timeLeft.minutes = "0" + timeLeft.minutes;
      }
      if (timeLeft.seconds < 10) {
        timeLeft.seconds = "0" + timeLeft.seconds;
      }
    }
    return timeLeft;
  } else {
    return false;
  }
};