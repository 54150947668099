import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout/index";
import DashboardLayout from "src/layouts/DashboardLayout1";
import ForgetasswordLink from "src/views/Auth/forget-password-link";
import Login from "src/views/Auth/forget-password-link/OtpVerify";
import Reset from "src/views/Auth/reset-password/index.js";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/LogIn")),
  },

  {
    exact: true,
    guard: true,
    path: "/dash",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/dash")),
  },
  {
    exact: true,
    guard: true,
    path: "/userManagement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserManagement/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/reset",
    layout: Reset,
    component: lazy(() => import("src/views/Auth/reset-password/index.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/SetPassword")),
  },
  {
    exact: true,
    guard: true,
    path: "/award-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/awardManagement/award.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/award-plan-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/awardPlanManagement")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-content-terms",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/awardManagement/viewAward.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-user",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/viewProfile")),
  },
  {
    exact: true,
    guard: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/profile")),
  },
  {
    exact: true,
    guard: true,
    path: "/profileEdit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ProfileComp/profileEdit")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-award-detail",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/awardManagement/viewAward.js")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/bonus-index",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/bonusManagement/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/bonus-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/bonusManagement/newindex")),
  },
  {
    exact: true,
    guard: true,
    path: "/coupon-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/couponManagement/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/tds-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/tdsManagement/index.js")),
  },

  {
    exact: true,
    guard: true,
    path: "/reward-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/rewardManagement/index.js")),
  },

  {
    exact: true,
    guard: true,
    path: "/loyalty-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/loyaltyManagement/index.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-tds",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/tdsManagement/viewTds.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/coupon-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/couponManagement/couponDetail.js")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/course-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/courseManagement")),
  },
  {
    exact: true,
    guard: true,
    path: "/withdraw-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/withdrawManagement/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-withdraw",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/withdrawManagement/viewWithdrawl")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/insurance-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/insuranceManagement")),
  },
  {
    exact: true,
    guard: false,
    path: "/view-insurance",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/insuranceManagement/viewInsurance.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/royalty-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/royaltyManagement")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-royalty-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/royaltyManagement/viewRoyalty.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/nominee-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/nomineeManagement")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-nominee-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/nomineeManagement/nomineeDetails")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/discount-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/discountManagement")),
  },
  {
    exact: true,
    guard: true,
    path: "/coupan-detail",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/couponManagement/couponDetail")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/bonus-table",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/bonusManagement/bonusMangement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/bonus-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/bonusManagement/bonusDetails.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/notificationManagement/notification.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/notification-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/notificationManagement/notificationDetails.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-notification",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/notificationManagement/editNotification.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-notification",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/notificationManagement/addNotification")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/static-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/staticContentManagement/viewContent")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-static-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/staticContentManagement/editContent")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/faq-management",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/faqManagement/index.js")),
  },

  {
    exact: true,
    guard: true,
    path: "/add-faq",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/faqManagement/addFaq.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-banner-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/bannerManagement/editBanner.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/banner-management",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/bannerManagement/banner.js")),
  },
  {
    exact: true,
    guard: true,
    path: "/contact-support",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/contactSupportManagement/index")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-contact-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/contactSupportManagement/viewContact")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/feedback",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/feedback/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/transacation",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/transactionHistory/transactionHistory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/transaction-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/transactionHistory/transactionDetails.js")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/Kyc-Management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/KYCManagement/kycManage")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-Kyc-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/KYCManagement/viewKYC")),
  },
  {
    exact: true,
    guard: true,
    path: "/Kyc-Management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/KYCManagement/kycManage")),
  },
  {
    exact: true,
    guard: true,
    path: "/view-KYC",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/KYCManagement/viewKYC")),
  },

  {
    exact: true,
    guard: true,
    path: "/tradedetails",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/tradeDetails")),
  },

  {
    exact: true,
    //  guard: false,
    path: "/forgot",
    layout: LoginLayout,
    component: lazy(() => import("src/views/Auth/forget-password/index")),
  },
  {
    exact: true,
    guard: false,
    path: "/verify-otp",
    layout: Login,
    component: lazy(() =>
      import("src/views/Auth/forget-password-link/OtpVerify")
    ),
  },

  {
    exact: true,
    guard: false,
    path: "/settlement",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/settlement")),
  },

  {
    exact: true,
    guard: true,
    path: "/video-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/videoManagement/VideoCategoryandSubcategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/manage-videos",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/videoManagement/ManageVideos")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/manage-add",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/videoManagement/ManageAdd")),
  },
  {
    exact: true,
    guard: true,
    path: "/manage-view",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/videoManagement/ManageView")),
  },
  {
    exact: true,
    guard: true,
    path: "/manage-edit",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/videoManagement/ManageEdit")),
  },

  {
    exact: true,
    guard: true,
    path: "/video-categories",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/videoManagement/VideoCategoryandSubcategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/category-add",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/videoManagement/CategoryAdd")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/category-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/videoManagement/CategoryView")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/category-edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/videoManagement/CategoryEdit")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-category-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/productCategoryManagement/ProductCategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-category-management-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/productCategoryManagement/View")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-category-management-edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/productCategoryManagement/Edit")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-category-management-add",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/productCategoryManagement/Add")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/coupon-add",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/couponManagement/AddCoupon")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/review-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/reviewManagement/ReviewManagement")
    ),
  },
  // <<<<<<< HEAD
  // {
  //   exact: true,
  //   guard: false,
  //   path: "/add-video-management",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/videoManagement/addVideo.js")),
  // },
  {
    exact: true,
    guard: true,
    path: "/category-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/Index")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-category-detail",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/ViewCategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-category-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/AddCategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-category-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/EditCategoryManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-slot",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/AddSlot")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/slot-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/SlotList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-teacher",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/AddTeacher")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/teacher-detail",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/ViewTeacher")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-teacher",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/EditTeacher")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-livesession-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/ViewLiveSessionList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-enroll-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/LiveSessionManagement/ViewEnrollUser")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-catelogue-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ProductCatelogueManagement/ProductCatelogueMan")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-catelogue-add",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ProductCatelogueManagement/ProductCatelogAdd")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-catelogue-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ProductCatelogueManagement/ProductCatelogView")
    ),
  },
  {
    exact: true,
    guard: false,
    path: "/product-catelogue-edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ProductCatelogueManagement/ProductCatelogEdit")
    ),
  },
  // {
  //   exact: true,
  //   guard: true,
  //   path: "/product-catelogue-delete",
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/ProductCatelogueManagement/ProductCatelogDelete")
  //   ),
  // },
  {
    exact: true,
    guard: true,
    path: "/product-deal-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ProductDealManagement/ProductDealManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-deal-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ProductDealManagement/DealView")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-deal-edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ProductDealManagement/DealEdit")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-deal-add",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/ProductDealManagement/DealAdd")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/order-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/OrderManagement/OrderManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/order-view",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OrderManagement/OrderView")),
  },
  {
    exact: true,
    guard: true,
    path: "/add-reward",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/rewardManagement/AddReward")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
