import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    // backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    padding: theme.spacing(6),
    position: "fixed",
    top: 0,
    width: "100%",
  },
  // loader:{
  //     width:100,
  //     maxWidth:"100%",
  //     margin: "auto",
  // },
  loader: {
    color: "#f2542d",
  },
  progressBar: {
    height: "2px",
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box width={300}>
        {/* <LinearProgress height={10} /> */}
        {/* <img className={classes.loader} src="/images/loader.png" alt="loader" /> */}
        <CircularProgress className={classes.loader} />
      </Box>
    </div>
  );
}
