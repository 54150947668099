export const BASE_URL = "https://node-botgfmi.mobiloitte.io/api/v1";
// export const BASE_URL = "http://172.16.0.47:3031/api/v1"; // Sourabh Sir local
// export const BASE_URL = "http://172.16.0.26:3031/api/v1"; // Digvijay local
// export const BASE_URL = "http://172.16.6.34:3031/api/v1"; // Digvijay local

const ApiConfig = {
  // Deepanshu Api Inte
  getFilteredProducts: `${BASE_URL}/product/getFilteredProducts`,
  getAllDealProducts: `${BASE_URL}/product/getAllProductsWithDeals`,
  getAllOrders: `${BASE_URL}/product/orderslist`,
  getLiveAssesment: `${BASE_URL}/user/fetchAssessmentvideos`,
  uploadImage: `${BASE_URL}/admin/uploadImage`,
  deleteAssesmentVideo: `${BASE_URL}/admin/deleteAssesmentVideo`,
  addDeal: `${BASE_URL}/product/addUpdateProductdeal`,
  adminLiveSessionsList: `${BASE_URL}/slots/adminLiveSessionsList`,
  getUtilityTransactionsList: `${BASE_URL}/admin/getUtilityTransactionsList`,
  getAllWallets: `${BASE_URL}/internal-wallet/get-all-wallets`,
  getAllFeedback: `${BASE_URL}/feedback/listFeedback`,
  createSlots: `${BASE_URL}/slots/createSlots`,
  transactionListOfUser: `${BASE_URL}/internal-wallet/transactionListOfUser`,
  getAllCoupon: `${BASE_URL}/admin/getCoupon`,
  addCoupon: `${BASE_URL}/admin/addCoupon`,
  deleteCoupon: `${BASE_URL}/admin/deleteCoupon`,



  // Manish Api Inte
  getCart: `${BASE_URL}/product/getCart`,
  getAllKycRequest: `${BASE_URL}/kyc/listAllKycRequest`,
  getKycDetails: `${BASE_URL}/kyc/viewKycDetail`,
  addUpdateProductCateogory: `${BASE_URL}/product/addUpdateProductCategory`,
  fetchProduct: `${BASE_URL}/product/fetchProduct`,
  deleteProduct: `${BASE_URL}/product/deleteProduct`,
  deleteProductdeal: `${BASE_URL}/product/deleteProductdeal`,
  getReward: `${BASE_URL}/admin/getReward`,
  deleteReward: `${BASE_URL}/admin/deleteReward`,
  addUpdateReward: `${BASE_URL}/admin/AddUpdateReward`,

  // Arpit and Manish Api Inte
  addallVideos: `${BASE_URL}/video/addVideo`,
  getAllVideosfetch: `${BASE_URL}/admin/fetchAssessmentvideos`,
  deleteSavedVideos: `${BASE_URL}/video/deleteVideo`,
  EditVideos: `${BASE_URL}/video/editVideo`,
  addVideoCategoriesandSubcategories: `${BASE_URL}/admin/AddUpdateVideoCategory`,
  getVideosCategoryAll: `${BASE_URL}/admin/fetchvideoCategory`,
  getImageUrl: `${BASE_URL}/admin/uploadImage`,
  getVideoUrl: `${BASE_URL}/admin/getvideoUrl`,
  addAssessmentVideos: `${BASE_URL}/admin/addUpdateassesmentVideo`,
  blockUnblockVideos: `${BASE_URL}/admin/blockUnblockAssesmentVideo`,

  // Ravi Api Inte
  //// Live Session Management
  fetchProductCategory: `${BASE_URL}/product/fetchProductCategory`,
  categoryList: `${BASE_URL}/category/categoryList`,
  createCategory: `${BASE_URL}/category/createCategory`,
  viewCategory: `${BASE_URL}/category/viewCategory`,
  deleteCategory: `${BASE_URL}/category/deletecategory`,
  editCategory: `${BASE_URL}/category/editCategory`,
  blockUnblockCategory: `${BASE_URL}/category/blockUnblockCategory`,
  createTeacher: `${BASE_URL}/category/createTeacher`,
  deleteTeacher: `${BASE_URL}/category/deleteTeacher`,
  listslots: `${BASE_URL}/slots/listslots`,
  viewTeacher: `${BASE_URL}/category/viewTeacher`,
  teacherList: `${BASE_URL}/category/teacherList`,
  blockUnblockTeacher: `${BASE_URL}/category/blockUnblockTeacher`,
  adminLiveSessionsList: `${BASE_URL}/slots/adminLiveSessionsList`,
  adminLiveSessionsView: `${BASE_URL}/slots/adminLiveSessionsView`,
  addUpdateproduct: `${BASE_URL}/product/addUpdateproduct`,
  getImageUrl: `${BASE_URL}/admin/uploadImage`,
  getVideoUrl: `${BASE_URL}/admin/getvideoUrl`,
  addAssessmentVideos: `${BASE_URL}/admin/addUpdateassesmentVideo`,
  blockUnblockVideos: `${BASE_URL}/admin/blockUnblockAssesmentVideo`,
  deleteVideoCategory: `${BASE_URL}/admin/deleteVideoCategory`,
};

export default ApiConfig;
